.App {
  text-align: center;
}

.App-header {
  background-color: rgb(202, 202, 202);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
  color: black;
}

.image-text {
  position: relative;
  text-align: center;
}

.tristan {
  width: 100%;
  pointer-events: none;
  -webkit-filter: grayscale(100%) blur(0.5vh);
  filter: grayscale(100%) blur(1px);
}

.tristan-text {
  color: white;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 2px 2px 10px black;
}

.project-logo {
  width: 25%;
  justify-content: left;
  align-items: baseline;
  text-align: left;
  border-radius: 75px;
}

.project {
  background: linear-gradient(145deg, #e1e2e4, #f3f3f3);
  box-shadow: 29px 29px 59px #e0e0e0, -29px -29px 59px #dadbdb;
  border-radius: 90px;
  margin-left: 10%;
  margin-right: 10%;
  max-width: 690px;
}

.project-content {
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.project-title {
  color: #000000;
}

.project-text {
  color: #000000;
}

.project-link {
  text-decoration: none;
}

.working-on-projects {}